import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-intro',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
    text = new Map<string, number>([
        ["Hello", 100],
        [", ", 1000],
        ["my ", 150],
        ["name ", 200],
        ["is ", 150],
        ["Sander ", 250],
        ["Smets", 200],
        [".", 2000]
    ])
    display = "";
    numberOfWords = 0;

    ngOnInit() {
        this.typing(this);
    }

    typing(that) {
        let totalWords = that.text.size;

        if (that.numberOfWords < totalWords) {
            let currentWord = [...that.text][that.numberOfWords][0]
            that.display += currentWord;
            that.numberOfWords ++;
            setTimeout(that.typing, that.text.get(currentWord), that);
        }
    }
}
