import { Component } from '@angular/core';
import { InfoItem } from "./info-item";
import { animate, query, stagger, style, transition, trigger } from "@angular/animations";


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [
        trigger('slideItemIn', [
            transition('* => *', [ // each time the binding value changes
                query(':enter', [
                    style({
                        transform: 'translateY(100%)',
                        opacity: 0
                    }),
                    stagger(100, [
                        animate('0.5s ease-in', style({
                            transform: 'translateY(0%)',
                            opacity: 1
                        }))
                    ])
                ], {optional: true})
            ])
        ])
    ]
})

export class MainComponent {

    items: Array<InfoItem> = new Array<InfoItem>();

    addItems(): void {
        this.items.push(
            {
                title: 'School',
                img: 'src1',
                content: ''
            },
            {
                title: 'Kdg',
                img: 'assets/kdg-dark.png',
                content: ''
            },
            {
                title: 'Ordina',
                img: 'assets/ordina.svg',
                content: ''
            },
            {
                title: 'Elewa',
                img: 'assets/elewa.png',
                content: ''
            }
        )
    }

}
