import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from "./main.component";
import { InfoCardComponent } from "./info-card/info-card.component";
import { CoreModule } from "../core/core.module";
import { AboutComponent } from './about/about.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
    declarations: [
        MainComponent,
        InfoCardComponent,
        AboutComponent,
        AboutMeComponent,
        ContactComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        ReactiveFormsModule
    ],
    exports: [
        MainComponent,
        InfoCardComponent
    ]
})
export class MainModule {
}
