import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactService } from "../../services/contact.service";
import { ContactDTO } from "../../services/contact.dto";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactForm: FormGroup;

    constructor(private contactService: ContactService) {
        this.contactForm = new FormGroup({
            name: new FormControl('', Validators.minLength(2)),
            email: new FormControl('', Validators.email),
            phone: new FormControl(''),
            city: new FormControl(''),
            subject: new FormControl('', Validators.required),
            message: new FormControl('', Validators.minLength(10)),
        })
    }

    ngOnInit() {
    }

    submit(value: any) {
        this.contactService.sendContactEmail(value as ContactDTO)
    }
}
