import { Component, Input } from '@angular/core';
import { InfoItem } from "../info-item";

@Component({
    selector: 'app-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {

    @Input() item!: InfoItem;
}
