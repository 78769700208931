import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    constructor(private viewportScroller: ViewportScroller) {
    }

    forceNavigate(name: string) {
        this.viewportScroller.scrollToAnchor(name);
    }

}
