import { Injectable } from '@angular/core';
import { ContactDTO } from "./contact.dto";

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor() {
    }

    sendContactEmail(contactDTO: ContactDTO) {
        console.log(contactDTO);

    }

}
