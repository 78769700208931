import { Component, HostListener, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from "@angular/animations";
import { MainComponent } from "./main/main.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('introOut', [
            transition(':leave', [
                animate('300ms ease-in', style({
                    transform: 'translateY(-1500%)',
                    opacity: 0
                }))
            ])
        ])
    ]
})
export class AppComponent {
    title = 'smets-it';

    intro = true;

    @ViewChild(MainComponent, {static: true}) child: MainComponent;

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.intro) {
            this.child.addItems();
        }

        this.intro = false;
    }
}
